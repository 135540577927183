import React from "react";
import { connect } from "react-redux";
import LoginPage  from "./pages/login";
import UploadPage from "./pages/upload";

class Router extends React.Component {
  render() {
    if (this.props.token) {
      return <UploadPage {...this.props} />
    } else {
      return <LoginPage {...this.props} />
    }
  }
}

export default connect(store=>({token:store.auth.token}))(Router)