import { SET_AUTH  } from "../actionTypes";

const INITAL_STATE = {
  user: {},
  isAuth: false,
  token: null
}

const  signIn = (state = INITAL_STATE, {payload}) => {
  return {
    ...state,
    user: payload.user,
    token: payload.token
  }
}

export default (state = INITAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH:
      return signIn(state, action)
    default:
      return state
  }
}