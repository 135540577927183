import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";
import StepProgressbar from "../stepProgressbar";
import formatSize from "../../utils/formatSize"
import "./style.less"


const VerifyDataComponent = ({fileProps}) => {
  if(!fileProps) return null
  const {name, size, type} = fileProps
  return (
    <DialogContent>
      <dl>
        <dt><b>Nome:</b></dt><dd>{name}</dd>
        <dt><b>Tamanho:</b></dt><dd>{formatSize(size)}</dd>
        <dt><b>Tipo:</b></dt><dd>{type}</dd>
      </dl>
    </DialogContent> 
  )
}

const FinishedDataComponent = () => {
  return (
    <DialogContent>
      <DialogContentText align="center">Dados enviados com sucesso!</DialogContentText>
    </DialogContent>
  )
}


/**
 * @UploadDialogModal componente para gerenciar uploads
 * @CriadoEm 19/08/2020
 */
function UploadDialogModal(props) {
  const {open, step, onChangeStep, canCancel, canFinish, canNext, onCancel} = props
  
  const handleClickOnNextButton = () => {
    onChangeStep(step + 1)
  }
  
  const renderDialogContent = () => {
    switch (step) {
      case 0:
        return VerifyDataComponent(props)
      case 1:
        return <StepProgressbar {...props} />
      case 2:
        return FinishedDataComponent(props)
      default:
        return null
    }
  }
  
  return (
    <Dialog open={open} keepMounted fullWidth maxWidth="lg" className="upload-dialog-modal">
      <DialogTitle>Enviar arquivo</DialogTitle>
      <Stepper alternativeLabel activeStep={step}>
        <Step key="verify"><StepLabel>Verificar dados</StepLabel></Step>
        <Step key="send"><StepLabel>Enviar dados para nuvem</StepLabel></Step>
        <Step key="finish"><StepLabel>Finalizar</StepLabel></Step>
      </Stepper>
      {renderDialogContent()}
      <DialogActions>
        {canCancel ? <Button onClick={onCancel} >Cancelar</Button> : null}
        {canFinish ? <Button color="primary">Finalizar</Button> : null}
        {canNext ? <Button color="primary" onClick={handleClickOnNextButton}>Próximo</Button> : null}
      </DialogActions>
    </Dialog>
  )
}

/**
 * @prop fileProps {{name:string, size:number, type:string}} informações do arquivo
 * @prop open {Boolean} Propriedade que diz se o dialog está visivel
 * @prop setp {Number} Propriedade que diz em qual passo o usuário está
 * @prop onChangeSep {Function} Propriedade que é disparada quando o usuário clica para ir para o próximo passo
 * @prop onCancel {Function} Propriedade que é disparada quando o usuário cancela o upload
 * @prop canCancel {Boolean} Proprieadade que diz se o passo atual é cancelavel
 * @prop canFinish {Boolean} Propriedade que diz se o passo atual é o ultimo
 * @prop canNext {Boolean} Propriedade que diz se o passo atual pode ser avançado
 * @prop progressBarValue {Number} Propriedade do valor da barra de progressp
 * @prop statusStack {Status} Lista de status do upload
 */
UploadDialogModal.propTypes = {
  fileProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }),
  open: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  canCancel: PropTypes.bool.isRequired,
  canFinish: PropTypes.bool.isRequired,
  canNext: PropTypes.bool.isRequired,
  progressBarValue: PropTypes.number.isRequired,
  statusStack: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["ERROR", "SUCCESS", "PROGRESS", "INFO"]).isRequired,
      current: PropTypes.number,
      total:PropTypes.number,
      message: PropTypes.string
    }),
  )
}

export default UploadDialogModal