import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  IconButton
} from '@material-ui/core'
import { ArrowBack, ArrowForward, Refresh } from '@material-ui/icons';
import FileDetailDialog from "../../components/fileDetailDialog";
import "./style.less"

const FILE_TYPE = {
  SUCCESS: 'Sucesso',
  INVALID_FILE: 'Arquivo inválido',
  UNDEFINED: 'Em progresso'
}

/**
 * @FileHistoryTable Tabela com histórico dos arquivos
 * @CriadoEm 24/08/2020
 */
const FileHistoryTable = ({ files, page, canGoForward, canGoBackward, onForward, onBackward, onRefresh }) => {
  const [detailsFile, setDetailsFile] = useState({})
  const [detailsOpen, setDetailsOpen] = useState(false)

  const getFileStatus = (file) => {
    const status = file.status[file.status.length - 1].status
    return FILE_TYPE[status]
  }

  const handleClickDetails = (file) => {
    const { name, size, date } = file
    setDetailsFile({ name, size, date, status: getFileStatus(file) })
    setDetailsOpen(true)
  }

  const handleCloseDetails = () => {
    setDetailsOpen(false)
  }

  return (
    <div className="fileHistoryTable">
      <div className="refresh-area">
        <IconButton onClick={onRefresh}>
          <Refresh />
        </IconButton>
      </div>
      <TableContainer component={Paper} >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Criado em</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Ultima atualização</TableCell>
              <TableCell>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              files.map((file) => (
                <TableRow key={file.id}>
                  <TableCell>{new Date(file.date).toLocaleString()}</TableCell>
                  {
                    file.status ?
                      <>
                        <TableCell>{getFileStatus(file)}</TableCell>
                        <TableCell>{new Date(file.status[file.status.length - 1].date).toLocaleString()}</TableCell>
                      </>
                      : (
                        <>
                          <TableCell> - </TableCell>
                          <TableCell> - </TableCell>
                        </>
                      )
                  }
                  <TableCell>
                    <Link component="button" variant="body2" onClick={() => handleClickDetails(file)} >Detalhes</Link>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination">
        <IconButton disabled={!canGoBackward} onClick={onBackward} >
          <ArrowBack />
        </IconButton>
        {page || 1}
        <IconButton disabled={!canGoForward} onClick={onForward}>
          <ArrowForward />
        </IconButton>
      </div>
      <FileDetailDialog open={detailsOpen} file={detailsFile} onClose={handleCloseDetails} />
    </div>
  )
}

/**
 * @typedef File
 * @property {number} size
 * @property {number} date
 * @property {Array<{date:number, status:string}>} Status
 * @property {string} id
 * @property {string} name
 * @property {type} string
 */

/**
 * @prop files {Array<File>} Lista de arquivos para a tabela renderizar
 * @prop page {number} Numero da página atual
 * @prop canGoForward {Boolean} Propriedade que diz se é possível ir para uma próxima página
 * @prop canGoBackward {Boolean} Propriedade que diz se é possível ir para página anterior
 * @prop onForward {Function} Função que é executada quando o usuário clica para ir para a próxima página
 * @prop onBackward {Function} Função que é executada quando o usuário clica para ir para a página anterior
 * @prop onRefresh {Function} Função que é executada quando o usuário clica para dar um refresh na tabela
 */
FileHistoryTable.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number,
      date: PropTypes.number,
      status: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.number,
          status:PropTypes.string
        })
      ),
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string
    })
  ),
  page: PropTypes.number.isRequired,
  canGoForward: PropTypes.bool.isRequired,
  canGoBackward: PropTypes.bool.isRequired,
  onForward: PropTypes.func.isRequired,
  onBackward: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired
}

export default FileHistoryTable