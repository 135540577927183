import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardContent, TextField, CardHeader, CardActions, Button, Backdrop, CircularProgress,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { setAuth } from '../../store/actions';
import { login } from '../../api';
import './style.less';


class LoginView extends React.Component {
  state = {
    login: '',
    password: '',
    backdrop: false,
  }

  setLogin(event) {
    const login = event.target.value;
    this.setState(prevState => ({ ...prevState, login }));
  }

  setPassword(event) {
    const password = event.target.value;
    this.setState(prevState => ({ ...prevState, password }));
  }

  setBackdrop(value) {
    this.setState(prevState => ({ ...prevState, backdrop: value }));
  }

  displayErroMessage(message) {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  }

  async handleSubmit(event) {
    const { dispatch } = this.props;
    try {
      this.setBackdrop(true);
      event.preventDefault();
      const { data } = await login(this.state);
      dispatch(setAuth({}, data.token));
    } catch (error) {
      if (error && error.response && error.response.data) {
        this.displayErroMessage(error.response.data.message);
      } else {
        this.displayErroMessage('Erro interno');
      }
    } finally {
      this.setBackdrop(false);
    }
  }


  render() {
    const { login, backdrop } = this.state;
    return (
      <main className="login-view-page">
        <img src={`${process.env.PUBLIC_URL}/logo.jpg`} alt="Brasilseg logo" />
        <Card component="form" onSubmit={(event) => { this.handleSubmit(event); }} className="login-card">
          <CardHeader title="Sign In" />
          <CardContent>
            <TextField
              required
              className="input"
              label="Login"
              helperText="Faça login usando suas credenciais da AWS ou com seu login de rede"
              value={login}
              onChange={(event) => { this.setLogin(event); }}
            />
            <TextField
              required
              className="input"
              label="Senha"
              type="password"
              onChange={(event) => { this.setPassword(event); }}
            />
          </CardContent>
          <CardActions className="actions">
            <Button type="submit" color="primary">Entrar</Button>
          </CardActions>
        </Card>
        <Backdrop open={backdrop} style={{ zIndex: 999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </main>
    );
  }
}

LoginView.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(store => ({ token: store.auth.token }))(withSnackbar(LoginView));
