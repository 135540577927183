import axios from 'axios';
import { API_URL, AUTH_API_URL } from './config/env';
import store from './store';

const API = axios.create({
  baseURL: API_URL,
});

const AUTH_API = axios.create({
  baseURL: AUTH_API_URL,
});

export const fetchFileType = async () => {
  const { auth: { token } } = store.getState();
  const { data } = await API.get('configuration/files', {
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export const fetchFileHistory = async ({ type, lastKeyId, lastKeyDate }) => {
  const { auth: { token } } = store.getState();
  const { data } = await API.get(`upload-planilha/planilha/${type}`, {
    params: { lastKeyId, lastKeyDate },
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export const addFileHistory = async ({ name, size, type }) => {
  const { auth: { token } } = store.getState();
  const { data } = await API.post(`upload-planilha/planilha/${type}`,
    { name, size },
    {
      headers: {
        Authorization: token,
      },
    });
  return data;
};

export const login = ({ login, password }) => AUTH_API.post('login', {
  userName: login,
  password,
});

export const getPresignedPost = async (fileName) => {
  const { auth: { token } } = store.getState();
  const { data } = await API.get('upload-planilha/request-token', {
    params: { fileName },
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export const uploadFile = ({
  file, fields, url, onUploadProgress,
}) => {
  const form = new FormData();
  Object.keys(fields).forEach(key => form.append(key, fields[key]));
  form.append('file', file);
  return axios({
    method: 'POST',
    url,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const getFileById = async (fileId) => {
  const { auth: { token } } = store.getState();
  const { data } = await API.get(`arquivo/${fileId}`, {
    headers: {
      Authorization: token,
    },
  });
  return data;
};
