import React from 'react'
import PropTypes from 'prop-types'
import { Dialog,DialogContent, DialogTitle, Typography, DialogActions, Button } from '@material-ui/core'
import formatSize from "../../utils/formatSize"

/**
 * @DialigDetailFile componente para mostrar dialog com detalhes de um arquivo
 * @CriadoEm 24/08/2020
 */
const DialogDetailFile = ({file, onClose, open}) => {
  const {name, size, date, type, status} = file
  return (
    <Dialog open={open} >
      <DialogTitle>Detalhes do arquivo</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Arquivo: {name}
        </Typography>
        <Typography gutterBottom>
          Tamanho: {size?formatSize(size):0}
        </Typography>
        <Typography gutterBottom>
          Data de criação: { new Date(date).toLocaleString() }
        </Typography>
        <Typography>
          Status: {status}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * @prop file {{name: string, size:number, date:number, type:string}} propriedade do arquivo que vai ser exibido
 * @prop open {Boolean} propriedade que diz se o dialog está abertou não
 * @prop onCloe {Function} propriedade que é disparada quando o dialog tem que fechar
 * */
DialogDetailFile.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    date: PropTypes.number,
    type: PropTypes.string
  }),
  open: PropTypes.bool.isRequired,
  onClose:PropTypes.func
}

export default DialogDetailFile