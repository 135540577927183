import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Select,
  InputLabel,
  FormControl,
  Divider,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Uploader from '../../components/uploader';
import UploadDialog from '../../components/uploadDialog';
import FileHistoryTable from '../../components/fileHistoryTable';
import {
  fetchFileType,
  fetchFileHistory,
  addFileHistory,
  getPresignedPost,
  uploadFile as apiUploadFile,
  getFileById,
} from '../../api';
import './style.less';
import { FILE_MAX_SIZE_MB } from '../../config/env';

const FILE_MAX_SIZE = 1_048_576 * FILE_MAX_SIZE_MB;
const FILE_STATUS = {
  SUCCESS: 'SUCCESS',
  INVALID_FILE: 'INVALID_FILE',
  UNDEFINED: 'UNDEFINED',
};

class UploadPage extends React.Component {
  static filterFileList(file) {
    const { name, size } = file;
    const ext = name.split('.').pop();
    return (
      ['csv', 'xlsx', 'xlsm'].includes(ext)
      && size <= FILE_MAX_SIZE
    );
  }

  static getFileHistory({ type, lastKeyId, lastKeyDate }) {
    return fetchFileHistory({ type, lastKeyId, lastKeyDate });
  }

  static async observeFile(fileId) {
    const sleep = () => new Promise(resolve => setTimeout(() => { resolve(); }), 200);
    let attempts = 15;
    while (attempts >= 0) {
      // eslint-disable-next-line no-await-in-loop
      const { file } = await getFileById(fileId);
      const { status } = file.status[file.status.length - 1];
      if (status !== FILE_STATUS.UNDEFINED) {
        return file;
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep();
      attempts -= 1;
    }
    return null;
  }

  state = {
    type: '',
    uploadDialog: {
      open: false,
      step: 0,
      canCancel: false,
      canFinish: false,
      canNext: false,
      fileProps: null,
      progressBarValue: 0,
      statusStack: [],
    },
    file: null,
    fileTypes: [],
    files: [],
    pagination: {},
    page: 1,
  }

  componentDidMount() {
    this.getFileTypes();
  }

  async getFileTypes() {
    const { fileTypes } = await fetchFileType();
    this.setState(prevState => ({
      ...prevState,
      fileTypes: (fileTypes || []).sort((a, b) => (
        a.presentationName.localeCompare(b.presentationName)
      )),
    }));
  }


  setUploadActions({ canCancel, canFinish, canNext }) {
    const { uploadDialog } = this.state;
    if (canNext !== undefined) {
      uploadDialog.canNext = canNext;
    }

    if (canCancel !== undefined) {
      uploadDialog.canCancel = canCancel;
    }

    if (canFinish !== undefined) {
      uploadDialog.canFinish = canFinish;
    }

    this.setState(prevState => ({
      ...prevState,
      uploadDialog,
    }));
  }

  addStatusToStack(status) {
    const { uploadDialog } = this.state;
    uploadDialog.statusStack.push(status);
    this.setState(prevState => ({
      ...prevState,
      uploadDialog,
    }));
  }

  clearUploadDialog() {
    this.setState(prevState => ({
      ...prevState,
      uploadDialog: {
        open: false,
        step: 0,
        canCancel: false,
        canFinish: false,
        canNext: false,
        fileProps: null,
        progressBarValue: 0,
        statusStack: [],
      },
    }));
  }

  handleSelectFiles(files) {
    if (files.length > 0) {
      const file = files[0];
      this.setState(prevState => ({
        ...prevState,
        file,
        uploadDialog: {
          ...prevState.uploadDialog,
          open: true,
          fileProps: { name: file.name, size: file.size, type: prevState.type },
          setp: 0,
          canCancel: true,
          canFinish: false,
          canNext: true,
        },
      }));
    } else {
      this.displayErroMessage('Arquivo inválido.');
    }
  }

  checkErrorInFiles(files) {
    if (files.length > 0) {
      const { name, size } = files[0];
      const ext = name.split('.').pop();
      if (!['csv', 'xlsx', 'xlsm'].includes(ext)) {
        this.displayErroMessage('Formato do arquivo inválido.');
        return true;
      } else if (size > FILE_MAX_SIZE) {
        this.displayErroMessage(`Tamanho do arquivo superior a ${FILE_MAX_SIZE_MB} MB.`);
        return true;
      }
    }
    return false;
  }

  displayErroMessage(message) {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant: 'error' });
  }

  updateProgressBarValue({ total, loaded }) {
    const progress = loaded / total * 100;
    const { uploadDialog } = this.state;
    uploadDialog.progressBarValue = progress;
    this.setState(prevState => ({
      ...prevState,
      uploadDialog,
    }));
  }


  async uploadFile() {
    const { uploadDialog, file: stateFile } = this.state;
    try {
      this.setUploadActions({ canCancel: false, canFinish: false, canNext: false });
      this.addStatusToStack({
        type: 'INFO',
        message: 'Atualizando histórico de arquivos',
      });

      let file = await addFileHistory(uploadDialog.fileProps);

      this.addStatusToStack({
        type: 'INFO',
        message: 'Solicitando permissões para enviar arquivo',
      });

      const fileName = `on_hold/${file.fileHistory.id}_${file.fileHistory.name}`;
      const presignedPost = await getPresignedPost(fileName);


      this.addStatusToStack({
        type: 'INFO',
        message: 'Enviando arquivo',
      });

      await apiUploadFile({
        fields: presignedPost.fields,
        url: presignedPost.url,
        file: stateFile,
        onUploadProgress: (event) => {
          this.updateProgressBarValue(event);
        },
      });

      this.addStatusToStack({
        type: 'INFO',
        message: 'Verificando status do arquivo',
      });

      const newFile = await UploadPage.observeFile(file.fileHistory.id);
      if (newFile) {
        file = newFile;
      } else {
        file = file.fileHistory;
      }
      const fileStatus = file.status[file.status.length - 1].status;
      if (fileStatus === FILE_STATUS.INVALID_FILE) {
        this.addStatusToStack({
          type: 'ERROR',
          message: 'Arquivo inválido',
        });
      } else {
        this.addStatusToStack({
          type: 'SUCCESS',
          message: 'Arquivo enviado com sucesso',
        });
      }

      const { files } = this.state;
      files.unshift(file);
      this.setState(prevState => ({
        ...prevState,
        files,
      }));

      this.setUploadActions({ canCancel: true, canNext: true, canFinish: false });
    } catch (error) {
      if (error.response) {
        this.addStatusToStack({
          type: 'ERROR',
          message: error.response.data.message,
        });
      } else {
        this.addStatusToStack({
          type: 'ERROR',
          message: error.message,
        });
      }
      this.setUploadActions({ canCancel: true, canFinish: false, canNext: false });
    }
  }

  async handleChangeStep(step) {
    if (step === 3) {
      this.clearUploadDialog();
    } else {
      await this.setState(prevState => ({
        ...prevState,
        uploadDialog: { ...prevState.uploadDialog, step },
      }));
      switch (step) {
        case 1:
          this.uploadFile();
          break;
        default:
          break;
      }
    }
  }


  async handleChangeSelectType(event) {
    const { value } = event.target;
    const history = await UploadPage.getFileHistory({ type: value });
    this.setState(prevState => ({
      ...prevState,
      type: value,
      files: history.files,
      page: 1,
      pagination: {
        1: history.LastEvaluatedKey,
      },
    }));
  }

  async handlePaginationForward() {
    const { type } = this.state;
    const { page } = this.state;
    const { pagination } = this.state;
    const lastKey = pagination[page];
    const history = await UploadPage.getFileHistory({
      type,
      lastKeyId: lastKey.id,
      lastKeyDate: lastKey.date,
    });
    pagination[page + 1] = history.LastEvaluatedKey;
    this.setState(prevState => ({
      ...prevState,
      files: history.files,
      page: page + 1,
      pagination,
    }));
  }

  async handlePaginationBackward() {
    const { type } = this.state;
    const { page } = this.state;
    const { pagination } = this.state;
    const lastKey = pagination[page - 2];
    const history = await UploadPage.getFileHistory({
      type,
      lastKeyId: lastKey ? lastKey.id : undefined,
      lastKeyDate: lastKey ? lastKey.date : undefined,
    });
    this.setState(prevState => ({
      ...prevState,
      files: history.files,
      page: page - 1,
    }));
  }

  async refreshTable() {
    const { type, page, pagination } = this.state;
    const lastKey = pagination[page - 1];
    const history = await UploadPage.getFileHistory({
      type,
      lastKeyId: lastKey ? lastKey.id : undefined,
      lastKeyDate: lastKey ? lastKey.date : undefined,
    });
    this.setState(prevState => ({
      ...prevState,
      files: history.files,
    }));
  }

  render() {
    const {
      type, fileTypes, files, page, pagination, uploadDialog,
    } = this.state;
    return (
      <Container className="upload-page">
        <h1>
          <img src={`${process.env.PUBLIC_URL}/logo.jpg`} alt="Brasilseg logo" />
          Upload de arquivos
        </h1>
        <Divider />
        <FormControl className="form-control">
          <InputLabel htmlFor="file-type-select">Tipo de Arquivo</InputLabel>
          <Select
            native
            inputProps={{ name: 'file-type-select' }}
            value={type}
            onChange={(ev) => { this.handleChangeSelectType(ev); }}
          >
            <option value="" />
            {
              fileTypes.map(
                type => (
                  <option
                    key={type.name}
                    value={type.name}
                  >
                    {type.presentationName}
                  </option>
                ),
              )
            }
          </Select>
        </FormControl>

        <Uploader
          accept=".csv, .xlsx, .xlsm"
          onSelectFiles={files => this.handleSelectFiles(files)}
          filterFile={file => UploadPage.filterFileList(file)}
          onCheckErrorInFiles={files => this.checkErrorInFiles(files)}
        />
        <FileHistoryTable
          files={files}
          page={page}
          canGoForward={!!pagination[page]}
          canGoBackward={page > 1}
          onForward={() => { this.handlePaginationForward(); }}
          onBackward={() => { this.handlePaginationBackward(); }}
          onRefresh={() => { this.refreshTable(); }}
        />
        <UploadDialog
          open={uploadDialog.open}
          step={uploadDialog.step}
          canCancel={uploadDialog.canCancel}
          canFinish={uploadDialog.canFinish}
          canNext={uploadDialog.canNext}
          onChangeStep={step => this.handleChangeStep(step)}
          onCancel={() => this.clearUploadDialog()}
          fileProps={uploadDialog.fileProps}
          progressBarValue={uploadDialog.progressBarValue}
          statusStack={uploadDialog.statusStack}
        />
      </Container>
    );
  }
}

UploadPage.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(UploadPage);
