import React, { createRef } from 'react'
import PropTypes from "prop-types";
import { Paper } from '@material-ui/core';
import { CloudUpload } from "@material-ui/icons";
import "./style.less";

/**
 * @Uploader componente para pegar enviar arquivos para o react
 * @CriadoEm 19/08/2020
 */
function Uploader(props) {
  const fileInput = createRef();
  const { accept, filterFile, onSelectFiles, onCheckErrorInFiles } = props;
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleInputChange = () => {
    const files = [...fileInput.current.files];
    let filteredFiles = files;
    if (onCheckErrorInFiles !== undefined) {
      if(onCheckErrorInFiles(filteredFiles)) return;
    }
    if (filterFile !== undefined) {
      filteredFiles = files.filter(filterFile)
    }
    onSelectFiles(filteredFiles)
  }
  
  return (
    <Paper elevation={1} className="uploader-component" onClick={handleClick}>
      <h3>
        <CloudUpload fontSize="large" /> <br />
        Selecionar Arquivo
      </h3>
      <input
        ref={fileInput}
        type="file"
        onChange={handleInputChange}
        accept={accept}
      />  
    </Paper>
  );
}

/**
 * @prop accept {string} propriedade para definir os tipos de arquivos possívels de enviar
 * @prop filterFile {Function} função para filtrar arquivos enviados
 * @prop onSelectFile {Function} função disparada quando o processo de enviar arquivo terminar
 */
Uploader.propTypes = {
  accept: PropTypes.string,
  filterFile: PropTypes.func,
  onSelectFiles:PropTypes.func.isRequired
}

export default Uploader