import React from 'react';
import PropTypes from 'prop-types';
import {DialogContent, Box, LinearProgress, Typography} from '@material-ui/core'
import './style.less'

/**
 * @enum {string}
 */
const TYPES = {
  PROGRESS: 'PROGRESS',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  ERROR: 'ERROR'
}

/**
 * @StepProgressbar progress bar com log para acompanhamento
 * @CriadoEm 19/08/2020
 */
const StepProgressbar = (props) => {
  const { progressBarValue:value, statusStack } = props
  return (
    <DialogContent className="stepProgressBar">
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
      <Box component="pre">
        <>
          {statusStack ?
            statusStack.map((status, index) => {
              if (status.type === TYPES.PROGRESS) {
                return (
                  <div key={index} className="load">
                    <div className="label">Enviando dados</div>
                    <div className="dot">....................................................................................................................................................................................................................................</div>
                    <div className="value">{status.current}/{status.total}</div>
                  </div>
                )
              } else if (status.type === TYPES.SUCCESS) {
                return <p key={index} className="success">{status.message}</p>
              } else if (status.type === TYPES.ERROR) {
                return <p key={index} className="error">{status.message}</p>
              } else if (status.type === TYPES.INFO) {
                return <p key={index}>{status.message}</p>
              }
            })
            :
            null
          }
        </>
      </Box>
    </DialogContent>
  )
}

/**
 * @typedef Status
 * @property { TYPES } type tipo do status (ERROR para erros, SUCCESS para sucesso, INFO para log normal e PROGRESS para atualizar quantiade de dados que foi envada)
 * @property {number} current quantidade de dados processada
 * @property {number} total quantidade de dados total
 * @property {string} message mensagem de log para ser exibida
 */

/**
 * @prop progressBarValue {number} Propridade do valor da barra de progresso
 * @prop statusStack {Array<Status>} Pilha de status do upload 
 */
StepProgressbar.propTypes = {
  progressBarValue: PropTypes.number.isRequired,
  statusStack: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["ERROR", "SUCCESS", "PROGRESS", "INFO"]).isRequired,
      current: PropTypes.number,
      total: PropTypes.number,
      message: PropTypes.string

    })
  )

}

export default StepProgressbar